<template>
  <div>
    <v-container pb-0 pt-2>
      <page-title title="應付款列表" />
    </v-container>

    <v-container class="py-0 pr-0 mb-2 d-flex">
      <v-container class="py-0 my-4">
        <liff-list-search style="width: 100%;" class="" :listKey="listKey" />
      </v-container>
      <liff-list-filter style="width: 100%;" :listKey="listKey" />
    </v-container>

    <v-container class="py-0 mb-2 d-flex justify-end">
      <v-select class="apply-pay-pager" style="max-width: 165px" outlined dense :items="perPageOptions"
        v-model="perPage"></v-select>
    </v-container>

    <v-container class="py-0 mb-4">

      <list-item v-for="(data, index) in listData" :key="index" :listKey="listKey" :data="data">

      </list-item>
    </v-container>

    <!-- <liff-list :listKey="listKey"></liff-list> -->
  </div>
</template>

<script>
import listMixin from "@/components/list/mixins/listMixin.js"
import listConfig from "./applyPayOverview"

import liffListSearch from "components/liffList/listSearch/liffListSearch.vue";
import liffListFilter from "components/liffList/listFilter/liffListFilter.vue";

export default {
  mixins: [listMixin],
  components: {
      liffListFilter, liffListSearch,
      listItem: () => import('@/modules/store/views/applyPay/item/applyPayItem.vue'),
  },
  data: () => ({
    listKey: 'liff-store-apply-pay-overview',
    showModal: false,
    meta: {},
    date: '',
    params: {
      page: 1,
      per: 1
    }
  }),
  computed: {
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    createPager() {
        if (!this.pager) return false;
        return this.pager.pages > 1;
    },
    providerId() {
        return this.$route.params.providerId
    },
    listData() {
        return this.$store.getters[`list/${this.listKey}/data`];
    },
    pager() {
        return this.$store.getters[`list/${this.listKey}/pager`];
    },
    page: {
        get() {
            return this.$store.getters[`list/${this.listKey}/page`];
        },
        set(value) {
            this.$store.dispatch(`list/${this.listKey}/set`, {
                key: "page",
                value,
            });

            this.listActions.changeIndexCondition(true);
        },
    },
    perPageOptions() {
        return this.$store.getters[`list/${this.listKey}/perPageOptions`]
    },
    perPage: {
        get() {
            return this.$store.getters[`list/${this.listKey}/perPage`]
        },
        set(value) {
            this.$store.dispatch(`list/${this.listKey}/set`, {
                key: 'perPage',
                value,
            })

            // 更換每頁筆數後從第一頁開始
            this.$store.dispatch(`list/${this.listKey}/set`, {
                key: 'page',
                value: 1,
            })

            this.listActions.changeIndexCondition()
        },
    },
  },
  mounted() {
    this.$root.gaLogEvent('商店_應付款頁')
  },
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      const res = await this.$api.collection.applyPayApi.storeApplyPayIndex({
        store: this.storeId,
        params
      })
      res.data = res.data.filter(item => +item.provider_id === +this.providerId).map(item => ({
        apply_pay_id: item.applyPay_id,
        provider_id: item.provider_id,
        provider_name: item.provider_name,
        created_at: item.created_at,
        price: item.amount,
        count: item.count,
        orders: item.orders,
        updated_at: item.updated_at,
        status: item.status,
      }))

      return res
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

